import Auth0Guard from '../guards/Auth0Guard'

const ManyPetsAdminRoles = ['SysAdmin', 'Handler']

const metaData = {
  meta: {
    requiresAuth: true,
    role: ManyPetsAdminRoles,
  },
}

const routes = [
  {
    path: '/admin',
    name: 'admin-home',
    component: () => import('../../views/admin/Dashboard.vue'),
    ...metaData,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/admin/register',
    name: 'register',
    component: () => import('../../views/admin/Register.vue'),
    ...metaData,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/admin/profile',
    name: 'admin-profile',
    component: () => import('../../views/Profile.vue'),
    ...metaData,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/admin/claims-requiring-attention',
    name: 'admin-claims-requiring-attention',
    component: () => import('../../views/admin/ClaimsRequiringAttention.vue'),
    ...metaData,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/admin/submitted-claims',
    name: 'admin-submitted-claims',
    component: () => import('../../views/Claims.vue'),
    ...metaData,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/admin/claims/:caseId/losses/:lossId',
    name: 'admin-portal-claim',
    component: () => import('../../views/Claim.vue'),
    ...metaData,
    beforeEnter: Auth0Guard,
  },

  {
    path: '/admin/users',
    name: 'users',
    component: () => import('../../views/admin/Users.vue'),
    ...metaData,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/admin/users/:id',
    name: 'user',
    component: () => import('../../views/User.vue'),
    ...metaData,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/admin/practices',
    name: 'admin-practices',
    component: () => import('../../views/admin/Practices.vue'),
    ...metaData,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/admin/practices/:id',
    name: 'vet-practice-detail',
    component: () => import('../../views/admin/Practice.vue'),
    ...metaData,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/admin/practices/:id/users',
    name: 'vet-practice-users',
    component: () => import('../../views/admin/Users.vue'),
    ...metaData,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/admin/practices/:id/account-details',
    name: 'vet-practice-account-details',
    component: () => import('../../views/admin/AccountDetails.vue'),
    meta: {
      requiresAuth: true,
      role: ['SysAdmin'],
    },
    beforeEnter: Auth0Guard,
  },
  {
    path: '/admin/practices/:id/sync-claims/:pioId',
    name: 'sync-claims',
    component: () => import('../../views/admin/SyncClaims.vue'),
    ...metaData,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/admin/claims-for-processing',
    name: 'admin-claims-for-processing',
    component: () => import('../../views/admin/ClaimsforProcessing.vue'),
    ...metaData,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/admin/claims-pending',
    name: 'admin-claims-pending',
    component: () => import('../../views/admin/ClaimsPending.vue'),
    ...metaData,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/admin/claim/:id',
    name: 'claim',
    component: () => import('../../views/admin/Claim.vue'),

    ...metaData,
    beforeEnter: Auth0Guard,
  },
]

export default routes
