import Auth0Guard from '../guards/Auth0Guard'

const meta = {
  requiresAuth: true,
  role: 'PracticeManager',
}

const routes = [
  {
    path: '/hub',
    name: 'portal-home',
    component: () => import('../../views/portal/Dashboard.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/practice',
    name: 'practice',
    component: () => import('../../views/portal/Practice.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/practices',
    name: 'practices',
    component: () => import('../../views/portal/Practices.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/profile',
    name: 'portal-profile',
    component: () => import('../../views/Profile.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/account-details',
    name: 'portal-account-details',
    component: () => import('../../views/portal/AccountDetails.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/find-policy',
    name: 'find-policy',
    component: () => import('../../views/portal/FindPolicy.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/policy-information',
    name: 'policy-information',
    component: () => import('../../views/portal/PolicyInformation.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/submitted-claims',
    name: 'portal-claims',
    component: () => import('../../views/Claims.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/claims/:caseId/losses/:lossId',
    name: 'portal-claim',
    component: () => import('../../views/Claim.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },

  {
    path: '/hub/claims-for-processing',
    name: 'portal-claims-for-processing',
    component: () => import('../../views/portal/ClaimsForProcessing.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },

  {
    path: '/hub/requiring-attention',
    name: 'portal-claims-requiring-attention',
    component: () => import('../../views/portal/ClaimsRequiringAttention.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/document-requests',
    name: 'portal-document-requests',
    component: () => import('../../views/portal/DocumentRequests.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/claim-form/',
    name: 'claim-form',
    component: () => import('../../views/portal/ClaimForm.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
  {
    path: '/hub/new-claim-form/',
    name: 'new-claim-form',
    component: () => import('../../views/portal/NewClaimForm.vue'),
    meta,
    beforeEnter: Auth0Guard,
  },
]

export default routes
